import React, { useEffect } from "react";
import Cookies from "js-cookie";

const ContactABB: React.FC = () => {
  useEffect(() => {
    const userDataCookie = Cookies.get("user_data");
    const user_data = userDataCookie ? JSON.parse(userDataCookie) : null;
    const url = user_data
      ? "/CaseCreationForm.html?user=" +
        user_data.given_name +
        " " +
        user_data.family_name +
        "&email=" +
        user_data.email
      : "";
    const window_url = window.location.href;
    const parts = window_url.split("/");
    const host = parts[0];
    setTimeout(() => {
      const newTab = window.open(host + url, "_blank");
      if (newTab) {
        newTab.focus();
      } else {
      }
    }, 2000);
    // setTimeout(() => {
    //   window.location.href = host + url;
    // }, 2000);
  }, []);

  return (
    <div>
      <h1>Redirecting...</h1>
      <p>You will be redirected to Case Creation Form shortly.</p>
    </div>
  );
};

export default ContactABB;
