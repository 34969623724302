import React, { useState, useEffect } from "react";
import { getAllApplications } from "./services/myPnpServices";

const ImportExportApplication= () => {
  const [permitted, setPermitted] = useState(true);
  const [showPermissionModal, setShowPermissionModal] = useState(false); // New state for modal visibility
 
  useEffect(() => {
    callGetAllApplications();
  }, []);

  const callGetAllApplications = async () => {
    try {
      await getAllApplications();
    } catch (err: any) {
      if (err.response && err.response.status === 403) {
        setPermitted(false);
        setShowPermissionModal(true);
      } else {
        console.log(err);
      }
    }
  };

  return (
    <div>
      {!permitted &&
        showPermissionModal && ( // Conditional rendering of the modal
          <div className="modal-overlay">
            <div className="modal red-border">
              <div className="modal-content">
                <button
                  className="modal-close"
                  onClick={() => setShowPermissionModal(false)}
                >
                  &times;
                </button>
                <p>
                  You don’t have the required permissions to access Platform
                  Administration Application. Please contact the administrator.
                </p>
              </div>
            </div>
          </div>
        )}
      {permitted === true && (
        <div>
          <h1>ImportExportApplication</h1>
        </div>
      )}
    </div>
  );
};

export default ImportExportApplication;
