//routes constants
export const Home = "/home";
export const SERVICES = "/services";
export const EXTENDED_SOLUTIONS = "/extended_solutions";
export const PLATFORM_ADMINISTRATOR = "/platform_administrator";
export const ADD_APPLICATION = "/AddApplication";

// alias names contants
export const ALIAS_INTRO = "home";
export const ALIAS_MY_LEARNINGS = "my_learning";
export const ALIAS_CONTACT_ABB = "contact_abb";
export const ALIAS_SERVICES = "services";
export const ALIAS_EXTENDED_SOLUTIONS = "extended_solutions";
export const ALIAS_PLATFORM_ADMINISTRATOR = "platform_administrator";
export const ALIAS_ADD_APPLICATION = "add_application"
export const ALIAS_APPLICATION_PROVISION = "application_provision";

//General constants
export const SEARCH_TRIGGER_LENGTH = 3;