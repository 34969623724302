// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.application-provision-section {
    display: flex;
    flex-direction: column;
    width: 100%;
  
    .home-content {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: 50px;
  
      .banner {
        margin-bottom: 10px;
      }
  
      .home-content {
        font-family: "ABBVoice-regular";
        font-size: 16px;
      }
    }
  }`, "",{"version":3,"sources":["webpack://./src/assets/styles/add_application.scss"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,WAAW;;IAEX;MACE,aAAa;MACb,sBAAsB;MACtB,mBAAmB;MACnB,gBAAgB;;MAEhB;QACE,mBAAmB;MACrB;;MAEA;QACE,+BAA+B;QAC/B,eAAe;MACjB;IACF;EACF","sourcesContent":[".application-provision-section {\n    display: flex;\n    flex-direction: column;\n    width: 100%;\n  \n    .home-content {\n      display: flex;\n      flex-direction: column;\n      align-items: center;\n      margin-top: 50px;\n  \n      .banner {\n        margin-bottom: 10px;\n      }\n  \n      .home-content {\n        font-family: \"ABBVoice-regular\";\n        font-size: 16px;\n      }\n    }\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
