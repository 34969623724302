import React, { useEffect } from "react";
import Cookies from "js-cookie";

const ServicePro: React.FC = () => {
  useEffect(() => {
    let service_pro_url =
      "https://stage.in.iapipulpandpaper.abb.com/servicepro";
    const window_url = window.location.href;
    if (window_url.includes("dev.in")) {
      service_pro_url = service_pro_url.replace("stage.in", "dev.in");
    }
    setTimeout(() => {
      window.location.href = service_pro_url;
    }, 2000);
  }, []);

  return (
    <div>
      <h1>Redirecting...</h1>
      <p>You will be redirected to Service Pro shortly.</p>
    </div>
  );
};

export default ServicePro;
