import React, { useEffect } from "react";
import { Routes, Route } from "react-router-dom";
import HMIComponent from "./HMIComponent";
import { useUserContext } from "./app_context/UserContext";
import { addRefreshToken } from "./utils/apiServices";
import { useNotification } from "./app_context/NotificationContext";
import { buildNotification } from "./utils/utils";
import { routeConfig } from "./utils/routes";

export default function Application() {
  const { userData } = useUserContext();
  const { showNotification } = useNotification();

  useEffect(() => {
    addRefreshToken(async () => {
      await userData.callRefreshToken();
    });
    // addNotification(() => {
    //   showNotification([
    //     buildNotification(
    //       "An error occurred while processing your request. Please try again later.",
    //       "alarm"
    //     ),
    //   ]);
    // });
  }, [userData]);

  return (
    <HMIComponent>
      {userData?.data?.access_token && (
        <Routes>
          {routeConfig.map((route) => (
            <Route key={route.path} path={route.path} element={route.element} />
          ))}
          <Route path="/user_preferences" element={null} />
          {/* this route is defined in Common HMI route */}
        </Routes>
      )}
    </HMIComponent>
  );
}
