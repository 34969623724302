import React, { useState, useEffect, useRef, useContext } from "react";
import "./App.css";
import { BrowserRouter } from "react-router-dom";
import {
  AppContext,
  AppGeneralTextContext,
  ImagesContext,
  HMIProps,
} from "./app_context/app_context";
import jsonData from "./app_data/app_data.json";
import generalTextData from "./app_data/generalTextData.json";
import imagesData from "./app_data/images_data.json";
import {
  addRequestEndListener,
  addRequestStartListener,
} from "./utils/apiServices";
import { NotificationProvider } from "./app_context/NotificationContext";
import Notification from "./components/common/Notification";
import Application from "./Application";
import Loader from "./components/common/Loder";
import { HMIContextProvider } from "./app_context/HMIContext";
import { UserProvider } from "./app_context/UserContext";
const App = () => {
  const [showLoader, setShowLoader] = useState<boolean>(false);

  useEffect(() => {
    addRequestStartListener(() => {
      setShowLoader(true);
    });
    addRequestEndListener(() => {
      setShowLoader(false);
    });
  }, []);

  return (
    <>
      <BrowserRouter>
        <UserProvider>
          <AppGeneralTextContext.Provider value={generalTextData}>
            <ImagesContext.Provider value={imagesData}>
              <AppContext.Provider value={jsonData as any}>
                <NotificationProvider>
                  <HMIContextProvider>
                    <>
                      <Application />
                      <Notification />
                    </>
                  </HMIContextProvider>
                </NotificationProvider>
              </AppContext.Provider>
            </ImagesContext.Provider>
          </AppGeneralTextContext.Provider>
        </UserProvider>
      </BrowserRouter>
      {showLoader && <Loader showOverlay />}
    </>
  );
};

export default App;
