import React, { useEffect, useState } from "react";
import { AppGeneralTextContext } from "./app_context/app_context";
import * as ABB from "@abb/abb-common-ux-react";
import { ReactSearchAutocomplete } from "react-search-autocomplete";
import { buildNotification } from "./utils/utils";
import { useNotification } from "./app_context/NotificationContext";
import {
  AssignedRoleToUser,
  getAllApplications,
  getAllRoles,
  getUserDetailsWithAppltnRole,
} from "./services/myPnpServices";
import { useNavigate } from "react-router-dom";

interface AllRoles {
  clientRole: boolean;
  composite: boolean;
  description: string;
  id: string;
  name: string;
  usersInRole: any;
  usersInRoleCount: number;
  container_id: string;
}
interface AllUsersList {
  id: string;
  name: string;
}
interface AllApplications {
  client_Name: string;
  client_id: string;
  application: string;
}
interface roleType {
  id: string;
  name: string;
  description: string;
  composite: boolean;
  clientRole: boolean;
  containerId: string;
}

const AssignRole = () => {
  const appGeneralTextData = React.useContext(AppGeneralTextContext);
  const { general_text_data }: any = appGeneralTextData;
  const [allRoles, setAllRoles] = useState<AllRoles[]>([]);
  const { showNotification } = useNotification();
  const [clientIds, setClientIds] = useState<string[]>([]);
  const [user, setUser] = useState<any>(null);
  const [selectedRoles, setSelectedRoles] = useState<any[]>([]);
  const [allUsers, setAllUsers] = useState<AllUsersList[]>([]);
  const [userDetails, setUserDetails] = useState<any[]>([]);
  const [taggedRoles, setTaggedRoles] = useState<any[]>([]);
  const [query, setQuery] = useState("");
  const applicationPlaceHolder: AllApplications[] = [
    { client_Name: "Select Applications", client_id: "", application: "" },
  ];
  const [allApplications, setAllApplications] = useState<AllApplications[]>(
    applicationPlaceHolder
  );
  const navigate = useNavigate();

  useEffect(() => {
    callGetAllApplications();
  }, []);

  useEffect(() => {
    if (clientIds.length > 0) {
      callGetUserRoles();
    }
  }, [clientIds]);

  useEffect(() => {
    getUserDetails(query);
  }, [query]);

  const handleRoleSelect = (value: any) => {
    const isRoleSelected = selectedRoles.some(
      (role: any) => role.value === value.value
    );
    const isRoleTagged = taggedRoles.some(
      (role: any) => role.value === value.value
    );

    if (!isRoleSelected && !isRoleTagged) {
      let role = {
        value: value.value,
        label: value.label,
      };
      setSelectedRoles([...selectedRoles, role]);
      setTaggedRoles([...taggedRoles, role]);
    }
  };

  const handleRemoveRole = (role: any) => {
    setTaggedRoles(taggedRoles.filter((r: any) => r.value !== role.value));
    setSelectedRoles(selectedRoles.filter((r: any) => r.value !== role.value));
  };
  const handleSave = () => {
    const selectedRolesData = taggedRoles
      .map((role: any) =>
        allRoles.find((allRole: any) => allRole.id === role.value)
      )
      .filter(Boolean);
    let payload = selectedRolesData.map((role: any) => ({
      id: role.id,
      name: role.name,
      description: role.description,
      composite: role.composite,
      clientRole: role.clientRole,
      containerId: role.container_id,
    }));
    assignRoles(payload);
  };

  const assignRoles = async (roles: roleType[]) => {
    try {
      let response = await AssignedRoleToUser(
        allApplications[0].client_id,
        user?.id,
        user?.username,
        roles
      );
      if (response) {
        showNotification([
          buildNotification(
            "New role is assigned successfully to the selected user!",
            "success"
          ),
        ]);
        navigate("/platform_administration/roles_user_management/user_mapping");
      }
    } catch(err:any) {
      showNotification([
        buildNotification("Failed to assign role to user!", "alarm"),
      ]);
    }
  };

  const callGetAllApplications = async () => {
    try {
      let result = await getAllApplications();
      if (result.length > 0) {
        let applications: AllApplications[] = result;
        const clientIdsArray = applications.map((app) => app.client_id);
        setClientIds(clientIdsArray);
        setAllApplications(applications);
      }
    } catch (err: any) {
      if (err.response && err.response.status === 403) {
        showNotification([buildNotification(err.response.data, "alarm")]);
      }
    }
  };

  const callGetUserRoles = async () => {
    try {
      const response = await getAllRoles(clientIds);
      setAllRoles(response.data);
    } catch (err) {
      console.log(err);
    }
  };

  const getUserDetails = async (query: string) => {
    try {
      let payload = {
        start: 0,
        max: 10,
        search: query,
      };
      let result = await getUserDetailsWithAppltnRole(payload);
      if (result) {
        setAllUsers(result.data);
        let users: AllUsersList[] = [];
        result.data.map((user: { id: any; email: any }) => {
          users.push({ id: user.id, name: user.email });
        });
        setAllUsers(users);
        setUserDetails(result.data);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleOnSearch = (search: string, results: any) => {
    setQuery(search);
  };

  const handleOnSelect = (item: any) => {
    const foundUserData = userDetails.find(
      (d: any) => d.email.toLowerCase() === item.name.toLowerCase()
    );

    if (foundUserData) {
      setUser(foundUserData);

      let roles: any = [];
      allRoles?.forEach((role) => {
        let includeRole = true;
        foundUserData?.roles.forEach((userRole: { id: string }) => {
          if (userRole.id === role.id) includeRole = false;
          return;
        });
        if (includeRole === true) roles.push(role);
      });

      let existingRoles: any[] = foundUserData?.roles.map((userRole: any) => {
        let matchedRole = allRoles.find((role: any) => role.id === userRole.id);
        return {
          value: matchedRole?.id,
          label: matchedRole?.name,
        };
      });
      setSelectedRoles([]);
      setTaggedRoles(existingRoles);
    }
  };

  return (
    <>
      <div className="assign-role-container">
        <div>
          <h1>{general_text_data.user_mapping_and_role}</h1>
        </div>

        <div className="assign-role-content-container">
          <div className="assign-role-title-container">
            <h3 className="assign-role-title">
              {general_text_data.assign_role}
            </h3>
          </div>
          <div className="input-container">
            <div className="lhs-input-column-one">
              <div>
                <label htmlFor="user">{general_text_data.user}</label>
                <br />
                <ReactSearchAutocomplete
                  items={allUsers}
                  onSearch={handleOnSearch}
                  onSelect={handleOnSelect}
                  onClear={() => {
                    setUser({});
                    setSelectedRoles([]);
                    setTaggedRoles([]);
                  }}
                  placeholder="Search user"
                  autoFocus
                  className="autosearch"
                />
              </div>
              <div className="role">
                <label htmlFor="role">{general_text_data.role}</label>
                <br />

                <ABB.Dropdown
                  onChange={(val) => handleRoleSelect(val[0])}
                  value={selectedRoles}
                  multiselect={false}
                  placeholder="Select Application"
                >
                  {allRoles?.map((e: any, id: any) => (
                    <ABB.DropdownOption
                      key={e.id}
                      label={e.name}
                      value={e.id}
                    />
                  ))}
                </ABB.Dropdown>
              </div>
            </div>
            <div className="rhs-text-area-container-and-btn-group">
              {taggedRoles && taggedRoles.length > 0 && (
                <div className="togged-roles">
                  <h3 className="togged-role-text">
                    {general_text_data.togged_roles}
                  </h3>
                  <div>
                    <div className="card-container">
                      {taggedRoles?.map((role: any, index: any) => (
                        <div key={index} className="role-card">
                          <span>{role.label}</span>
                          <button onClick={() => handleRemoveRole(role)}>
                            x
                          </button>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="assign-action-buttons">
            <ABB.Button
              type="discreet-blue"
              shape="pill"
              sizeClass="small"
              className="secondary-btn"
              text={general_text_data.cancel}
              onClick={() =>
                navigate(
                  "/platform_administration/roles_user_management/user_mapping"
                )
              }
            />
            <ABB.Button
              type="primary-blue"
              shape="pill"
              sizeClass="small"
              text={general_text_data.save}
              onClick={handleSave}
              disabled={
                !user ||
                user.lenght === 0 ||
                selectedRoles.length === 0 ||
                selectedRoles === null
              }
            />
          </div>
        </div>
      </div>
    </>
  );
};
export default AssignRole;
