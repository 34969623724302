import { Delete, Get, Post, Put } from "../utils/apiServices";

const {
  REACT_APP_URL_PREFIX,
  REACT_APP_ENV_PREFIX,
  REACT_APP_DOMAIN,
  REACT_APP_LOCATION,
} = process.env;

export const hostURL = `${REACT_APP_URL_PREFIX}${REACT_APP_ENV_PREFIX}${REACT_APP_LOCATION}${REACT_APP_DOMAIN}`;
const application_api = `${hostURL}/provisioningapi/api/ApplicationConfiguration`;
const authorization_api = `${hostURL}/authservapi/api`;

const getAllApplicationTypeEndPoint = `${application_api}/getAllApplicationType`;
const allapplicationConfigEndPoint = `${hostURL}/provisioningapi/api/ApplicationConfiguration/getAllApplicationConfig`;
const allappConfigurationEndPoint = `${hostURL}/provisioningapi/api/ApplicationConfiguration/getAllApplicationsByCategory`;
const getAllApplicationEndPoint = `${authorization_api}/PlatformAdmin/Applications`;
const deleteApplicationConfigEndPoint = `${application_api}/deleteApplicationConfig`;
const getAllPillarEndPoint = `${application_api}/getAllPillar`;
const createApplicationConfigEndPoint = `${application_api}/createApplicationConfig`;
const updateApplicationConfigEndPoint = `${application_api}/updateApplicationConfig`;
const getAllRolesEndPoint = `${authorization_api}/PlatformAdmin/GetAllRoles`;
const addNewRoleEndPoint = `${authorization_api}/PlatformAdmin/Role`;
const getUserDetailsEndPoint = `${authorization_api}/PlatformAdmin/GetAllUsersWithApplicationRoles`;
const deleteAssignedRoleEndPoint = `${authorization_api}/PlatformAdmin/UnassignClientRolesToUser`;
const deleteUserRoleEndPoint = `${authorization_api}/PlatformAdmin/Role`;
const assignClientRolesToUserEndPoint = `${authorization_api}/PlatformAdmin/AssignClientRolesToUser`;
const applicationsByCategoryEndPoint = `${application_api}/getAllApplicationsByCategory`;

export const getAllApplicationType = async (): Promise<any> => {
  const response = await Get<any, {}>(getAllApplicationTypeEndPoint, {}, {});
  return response;
};

export const getAllApplicationConfig = async (id: any): Promise<any> => {
  const endPoint = `${allapplicationConfigEndPoint}/${id}`;
  const response = await Get<any, {}>(endPoint, {}, {});
  return response;
};

export const getAllApplicationConfigurations = async (id: any): Promise<any> => {
  const endPoint = `${allappConfigurationEndPoint}/${id}`;
  const response = await Get<any, {}>(endPoint, {}, {});
  return response;
};

export const getAllApplications = async (): Promise<any> => {
  const response = await Get<any, {}>(getAllApplicationEndPoint, {}, {});
  return response;
};

export const deleteAppltnConfig = async (id: any): Promise<any> => {
  const endPoint = `${deleteApplicationConfigEndPoint}/${id}`;
  const response = await Put<any, {}>(endPoint, {}, {});
  return response;
};

export const getAllPillarData = async (): Promise<any> => {
  const response = await Get<any, {}>(getAllPillarEndPoint, {}, {});
  return response;
};

export const createAppltnConfig = async (data: any): Promise<any> => {
  const response = await Post<any, any>(
    createApplicationConfigEndPoint,
    data,
    {}
  );
  return response;
};

export const updateAppltnConfig = async (data: any): Promise<any> => {
  const response = await Put<any, {}>(
    updateApplicationConfigEndPoint,
    data,
    {}
  );
  return response;
};

export const getAllRoles = async (data: any): Promise<any> => {
  const response = await Post<any, any>(getAllRolesEndPoint, data, {});
  return response;
};

export const addNewRole = async (data: any): Promise<any> => {
  const response = await Post<any, any>(addNewRoleEndPoint, data, {});
  return response;
};

export const getUserDetailsWithAppltnRole = async (data: any): Promise<any> => {
  const response = await Post<any, any>(getUserDetailsEndPoint, data, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
  return response;
};

export const deleteAssignedRoleToUser = async (
  client_id: any,
  userid: any,
  userName: any,
  payload: any
): Promise<any> => {
  const endpoint = `${deleteAssignedRoleEndPoint}?client_id=${client_id}&userid=${userid}&userName=${userName}`;
  const response = await Delete<any, any>(endpoint, payload, {});
  return response;
};

export const AssignedRoleToUser = async (
  client_id: any,
  userid: any,
  userName: any,
  payload: any
): Promise<any> => {
  const endpoint = `${assignClientRolesToUserEndPoint}?client_id=${client_id}&userid=${userid}&userName=${userName}`;
  const response = await Post<any, any>(endpoint, payload, {});
  return response;
};
export const deleteUserRole = async (payload: any): Promise<any> => {
  const response = await Delete<any, any>(deleteUserRoleEndPoint, payload, {});
  return response;
};

export const getAllApplicationByCategory = async (id: any): Promise<any> => {
  const endPoint = `${applicationsByCategoryEndPoint}/${id}`;
  const response = await Get<any, {}>(endPoint, {}, {});
  return response;
};