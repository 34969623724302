import { HMI } from "pnp-common-hmi";
import { useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useHMI } from "./app_context/HMIContext";
import { useUserContext } from "./app_context/UserContext";

export default function HMIComponent({ children }: any) {
  const hmiRef = useRef<any>(null);
  const navigate = useNavigate();
  const { HMIData, setHMIData } = useHMI();
  const { setUserData } = useUserContext();
  // const triggerNestedFunction = () => {
  //   if (hmiRef.current) {
  //     hmiRef.current.triggerNestedFunction();
  //   }
  // };
  useEffect(() => {
    setUserData((prevData: any) => ({
      ...prevData,
      callRefreshToken: hmiRef.current?.refreshToken,
    }));
  }, [hmiRef]);

  return (
    <HMI
      {...HMIData}
      ref={hmiRef}
      getToken={(data: any) => {
        setUserData((prevData: any) => ({ ...prevData, data }));
      }}
      callReAuthenticate={() => console.log("second")}
    >
      {children}
    </HMI>
  );
}
